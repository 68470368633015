<template>
  <span>
    <q-btn-dropdown
      :flat="flat"
      rounded
      fab-mini
      style="min-height: 2.5em !important;min-width: 2.5em !important;padding:0 !important;"
      :color="customColor.length > 0 ? customColor : 'gray-medium-dark'"
      :dropdown-icon="customIcon.length > 0 ? customIcon : 'sym_r_more_horiz'"
      no-icon-animation
    >
      <q-list>
        <q-item
          v-for="(action, i) in actions"
          :key="'ActionsDropdownButtonAction_' + i"
          clickable
          v-close-popup
          @click="action.action"
          :disable="action.disabled"
        >
          <q-tooltip
            v-if="
              action.disabled &&
                'disabledTooltip' in action &&
                action.disabledTooltip.length > 0
            "
            anchor="center right"
            self="center left"
          >
            {{ action.disabledTooltip }}
          </q-tooltip>
          <q-item-section v-if="action.icon && action.icon.length > 0" avatar>
            <q-icon size="22px" :name="action.icon" color="subtle" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ action.label }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
    <q-tooltip anchor="center right" self="center left">
      {{ tooltip }}
    </q-tooltip>
  </span>
</template>

<script>
export default {
  name: "ActionsDropdownButton",
  props: {
    actions: {
      type: Array,
      required: true
    },
    tooltip: {
      type: String,
      default: "View Actions"
    },
    customIcon: {
      type: String,
      default: ""
    },
    customColor: {
      type: String,
      default: ""
    },
    flat: {
      type: Boolean,
      default: true
    },
    flipMenuSide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
